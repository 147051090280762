@import "https://cdn.jsdelivr.net/npm/bulma@0.9.3/css/bulma.min.css";

.body {
  margin: 0;
  font-family: 'Fira Code', monospace;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


.code {
  margin: 0;
  font-family: 'Fira Code', monospace;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}